<template>
  <div class="container-fluid">
    <div class="configuration-wrapper">
      <h1>{{ $t("APPS.CONFIGURATION_MODULE") }}</h1>
      <div class="kw-breadcrumb">
        <ul>
          <li>
            <img src="../../../../../public/img/kw-home.svg" alt="icon" />
            <span>{{ $t("APPS.CONFIGURATION_MODULE") }}</span>
          </li>
          <li v-if="$route.name === 'List SpotCategories'">
            {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
          </li>
          <li v-if="$route.name === 'List SpotTypes'">
            {{ $t("CONFIGURATION.SPOT_TYPES") }}
          </li>
          <li v-if="$route.name === 'List Equipments'">
            {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
          </li>
          <li v-if="$route.name === 'List EquipmentCategories'">
            {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
          </li>
        </ul>
      </div>
      <ul class="sub-menu-items">
        <li
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_CATEGORIES)"
        >
          <router-link
            :to="{ name: 'List SpotCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_TYPES)">
          <router-link
            :to="{ name: 'List SpotTypes' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_TYPES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENT_CATEGORIES)
          "
        >
          <router-link
            :to="{ name: 'List EquipmentCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENTS)">
          <router-link
            :to="{ name: 'List Equipments' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
              </span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <h3>{{ $t("CONFIGURATION.SPOT_TYPES") }}</h3>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openSpotTypeCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_SPOT_CATEGORIES)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("SPOT_TYPES.ADD_SPOT_TYPE")
            }}</span>
          </base-button>
        </div>
      </div>

      <spot-type-list-table
        @onViewSpotType="openSpotTypeViewModal"
        @onEditSpotType="openSpotTypeEditModal"
        @onDeleteSpotType="deleteSpotType"
        @onAddSpotType="openSpotTypeCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewSpotTypeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewSpotTypeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SPOT_TYPE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeSpotTypeModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("SPOT_TYPES.VIEW_SPOT_TYPE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-spot-type-component
              v-if="openSpotType"
              :spotTypeId="openSpotType.id"
              @onEditSpotType="openSpotTypeEditModal"
              @onDeleteSpotType="deleteSpotType"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditSpotTypeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditSpotTypeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'SPOT_TYPE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeSpotTypeModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("SPOT_TYPES.EDIT_SPOT_TYPE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-spot-type-component
              v-if="openSpotType"
              :spotTypeId="openSpotType.id"
              @onViewSpotType="openSpotTypeViewModal(openSpotType)"
              @onCloseSpotTypeModal="closeSpotTypeModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddSpotTypeModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddSpotTypeModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'SPOT_TYPE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeSpotTypeModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("SPOT_TYPES.ADD_SPOT_TYPE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-spot-type-component
              @onViewSpotType="openSpotTypeViewModal"
              @onCloseSpotTypeModal="closeSpotTypeModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SpotTypeListTable from "./partials/SpotTypeListTable.vue";
import EditSpotTypeComponent from "./components/EditSpotTypeComponent.vue";
import AddSpotTypeComponent from "./components/AddSpotTypeComponent.vue";
import ViewSpotTypeComponent from "./components/ViewSpotTypeComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    SpotTypeListTable,
    // NotificationSubscription,
    EditSpotTypeComponent,
    AddSpotTypeComponent,
    ViewSpotTypeComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const spotTypeId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewSpotTypeModalOpened = false;
    let isEditSpotTypeModalOpened = false;
    let isAddSpotTypeModalOpened = false;
    let openSpotType = null;
    if (spotTypeId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewSpotTypeModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditSpotTypeModalOpened = true;
      }
      openSpotType = { id: spotTypeId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddSpotTypeModalOpened = true;
    }
    return {
      isViewSpotTypeModalOpened: isViewSpotTypeModalOpened,
      isEditSpotTypeModalOpened: isEditSpotTypeModalOpened,
      isAddSpotTypeModalOpened: isAddSpotTypeModalOpened,
      openSpotType: openSpotType,
      renderKey: 1,
    };
  },

  methods: {
    openSpotTypeCreateModal() {
      this.closeSpotTypeModal();
      this.isAddSpotTypeModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SpotTypes",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openSpotTypeViewModal(spotType, reRender = false) {
      this.closeSpotTypeModal();
      this.openSpotType = spotType;
      this.isViewSpotTypeModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SpotTypes",
          query: { id: this.openSpotType.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openSpotTypeEditModal(spotType) {
      this.closeSpotTypeModal();
      this.openSpotType = spotType;
      this.isEditSpotTypeModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SpotTypes",
          query: { id: this.openSpotType.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeSpotTypeModal() {
      this.isAddSpotTypeModalOpened = false;
      this.isViewSpotTypeModalOpened = false;
      this.isEditSpotTypeModalOpened = false;
      this.openSpotType = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SpotTypes",
          query: {},
        }).href
      );
    },

    async deleteSpotType(spotType) {
      const confirmation = await swal.fire({
        text: this.$t("SPOT_TYPES.DELETE_THIS_SPOT_TYPE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("spotTypes/destroy", spotType.id);
          this.renderKey++;
          this.closeSpotTypeModal();
          this.$notify({
            type: "success",
            message: this.$t("SPOT_TYPES.SPOT_TYPE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
