export default {
  type: "spot-types",
  name: null,
  excerpt: "",
  created_at: null,
  relationshipNames: [
    "category",
  ],
  category: {
    type: "spot-categories",
    id: null,
  },
};
