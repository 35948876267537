<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ spotType.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="spotType.name">
          <dt>{{ $t("SPOT_TYPES.SPOT_TYPE_NAME") }}</dt>
          <dd>
            {{ spotType.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CATEGORY") }}</dt>
          <dd>
            <spot-category
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SPOT_CATEGORIES)"
              :spotCategory="spotType.category"
            />
            <span v-else>{{ spotType.category?.name }}</span>
          </dd>
        </dl>

        <dl class="row" v-if="spotType.services">
          <dt>{{ $t("SPOT_TYPES.SERVICES") }}</dt>
          <dd>
            {{ $t(`SPOT_TYPES.${spotType.services}`) }}
          </dd>
        </dl>

        <dl class="row" v-if="spotType.electricity">
          <dt>{{ $t("SPOT_TYPES.ELECTRICITY") }}</dt>
          <dd>
            {{ $t(`SPOT_TYPES.${spotType.electricity}`) }}
          </dd>
        </dl>

        <dl class="row full" v-if="spotType.excerpt">
          <dt>
            {{ $t("COMMON.COMMENT") }}
          </dt>
          <dd>
            <div v-html="spotType.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ spotType.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ spotType.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import SpotCategory from "@/components/SpotCategory";

export default {
  name: "spot-type-view-global",

  props: ["spotType"],

  components: { SpotCategory },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    contactUpdated() {
      this.$emit("spotTypeUpdated", true);
    },
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
